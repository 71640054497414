import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Grid, Hidden, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useStrings } from '../../assets/localization/strings'
import { color, theme } from '../../theme'
import ReactHookFormSelect, { IOption } from '../common/form/ReactHookFormSelect'
import { IUGCCampaign } from './UGCCampaignForm'

const CampaignBudget = () => {
  const { control } = useFormContext<IUGCCampaign>()
  const number = useWatch({ control, name: 'numberOfVideos' })
  const strings = useStrings()

  const options: IOption[] = [...Array(20)].map((_, i) => i + 1).map(i => ({ label: `${i}`, value: i }))
  options[2] = { label: '3', value: 3, note: strings.recommended }
  options[4] = { label: '5', value: 5, note: strings['5_discount'] }
  options[9] = { label: '10', value: 10, note: strings['10_discount'] }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h4'>{strings.campaign_creation_budget_video_count}</Typography>
      </Grid>
      {number < 3 && (
        <Grid item xs={12} mt={2}>
          <Alert severity='warning'>{strings.campaign_creation_budget_video_count_tip}</Alert>
        </Grid>
      )}
      <Grid item xs={12} mt={2}>
        <Typography variant='body2'>
          {strings.campaign_creation_budget_question}

          <Hidden smDown>
            <Tooltip title={strings.campaign_creation_budget_tip}>
              <IconButton>
                <InfoOutlinedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Typography>
      </Grid>
      <Grid container item xs={12} mt={2}>
        <Grid item xs={12} sm={6} pr={2}>
          <ReactHookFormSelect name='numberOfVideos' label={strings.campaign_creation_budget_videos_label} options={options} />
        </Grid>
        <Grid item xs={12} sm={6} pr={2} sx={{ [theme.breakpoints.only('xs')]: { mt: 2 } }} container alignItems='center'>
          <Typography variant='body2' display='inline'>
            {strings.campaign_creation_budget_info1}{' '}
            <Typography paragraph={false} display='inline' color={color.stylinkPurple} sx={{ textDecorationColor: color.stylinkPurple }}>
              {number == 1 ? strings.campaign_creation_budget_creator(number) : strings.campaign_creation_budget_creators(number)}
            </Typography>{' '}
            {strings.campaign_creation_budget_info2}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CampaignBudget
